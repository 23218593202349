import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class Head extends Component {
  render() {
    console.log(this.props)
    let description =
      this.props.language === 'de'
        ? 'Clemens Wilding ist ein Softwareentwickler und Berater in Berlin.'
        : 'Clemens Wilding is a software engineer and consultant in Berlin.'
    return (
      <Helmet>
        <html lang={this.props.language} />
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <title>Clemens Wilding</title>
      </Helmet>
    )
  }
}
