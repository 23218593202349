import React, { Component } from 'react'

import Head from '../components/head'
import Menu from '../components/menu'
import Landing from '../components/landing'
// import CV from '../components/cv'
import Contact from '../components/contact'
// import Clients from '../components/clients'
// import Technologies from '../components/technologies'
import Skills from '../components/skills'

import '../css/App.css'

export default class OnePagerDe extends Component {
  render() {
    return (
      <>
        <Head language="de" />
        <Menu language="de" />
        <Landing language="de" />
        {/* <Clients {...this.props} /> */}
        <Skills language="de" />
        <Contact language="de" />
        {/* <Technologies {...this.props} /> */}
      </>
    )
  }
}
