import React, { Component } from 'react'
import { translate } from '../helper'

export default class Contact extends Component {
  render() {
    // const lang = this.props.location.pathname.split('/')[1]
    const lang = this.props.language
    return (
      <section
        id={translate({ idDE: 'kontakt', idEN: 'contact' }, 'id', lang)}
        className="section contact"
      >
        <div className="section-content contact-content">
          <h3 className="section-content-title contact-content-title">
            {translate(
              { titleDE: 'Kontakt', titleEN: 'Contact' },
              'title',
              lang
            )}
          </h3>
          <div>Clemens Wilding</div>
          <div>c/o Offstudio</div>
          <div>Adalbertstr. 32</div>
          <div>D-10179 Berlin</div>
          <div>+49 30 206 652 40</div>
          <div>
            <a href="mailto:cwi@clemenswilding.ch">cwi@clemenswilding.ch</a>
          </div>
        </div>
        <div className="section-content implementation-content">
          <h3 className="section-content-title implementation-content-title">
            {translate(
              {
                titleDE: 'Umsetzung & Gestaltung',
                titleEN: 'Implementation & Design'
              },
              'title',
              lang
            )}
          </h3>
          <div>Clemens Wilding</div>
          <div>
            {translate(
              { textDE: 'Erstellt mit ', textEN: 'Made with ' },
              'text',
              lang
            )}
            <a href="https://www.gatsbyjs.org/">Gatsby</a>
            {translate({ textDE: ' und ', textEN: ' and ' }, 'text', lang)}
            <a href="https://reactjs.org/">React</a>
          </div>
          <div>
            {translate(
              { textDE: 'Schrift von ', textEN: 'Font by ' },
              'text',
              lang
            )}
            <a href="http://www.alfredomarcopradil.com/">
              Alfredo Marco Pradil
            </a>
          </div>
        </div>
      </section>
    )
  }
}
