import React, { Component } from 'react'
import { Link } from 'gatsby'

import { translate } from '../helper'

const MENU = [
  { nameEN: 'Home', slugEN: 'home', nameDE: 'Start', slugDE: 'start' },
  // {nameEN: 'Competences', slugEN: 'competences', nameDE: 'Kompetenzen', slugDE: 'kompetenzen'},

  { nameEN: 'Contact', slugEN: 'contact', nameDE: 'Kontakt', slugDE: 'kontakt' }
  // {nameEN: 'CV', slugEN: 'cv', nameDE: 'Lebenslauf', slugDE: 'lebenslauf'}
]

const LANG = {
  en: { name: 'english', slug: 'en', oppName: 'deutsch', oppSlug: 'de' },
  de: { name: 'deutsch', slug: 'de', oppName: 'english', oppSlug: 'en' }
}

export default class Menu extends Component {
  render() {
    // const lang = this.props.location.pathname.split('/')[1]
    const lang = this.props.language
    return (
      <div className="menu">
        <div className="menu-content">
          {MENU.map(entry => {
            return (
              <a
                key={translate(entry, 'slug', lang)}
                className="menu-link"
                href={'/' + lang + '#' + translate(entry, 'slug', lang)}
              >
                {translate(entry, 'name', lang)}
              </a>
            )
          })}
          <Link className="menu-link" to={'/' + LANG[lang].oppSlug}>
            {LANG[lang].oppName}
          </Link>
        </div>
      </div>
    )
  }
}
