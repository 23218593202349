import React, { Component } from 'react'

import { translate } from '../helper'

const SKILLS = [
  {
    acronym: 'SOA',
    nameDE: 'Serviceorientiertes Design und Implementierung',
    nameEN: 'Service oriented design and implementation'
  },
  {
    acronym: 'SI',
    nameDE: 'Integration von bestehenden Systemen',
    nameEN: 'Integration of existing systems'
  },
  {
    acronym: 'DIS',
    nameDE: 'Entwicklung von individuellen Gesamtlösungen',
    nameEN: 'Development of individual solutions'
  },
  {
    acronym: 'AOOSD',
    nameDE: 'Agile und objektorientierte Softwareentwicklung',
    nameEN: 'Agile and object oriented software development'
  },
  {
    acronym: 'FED',
    nameDE: 'Dynamische Frontend-Entwicklung',
    nameEN: 'Dynamic front end development'
  }
]

export default class Skills extends Component {
  render() {
    // const lang = this.props.location.pathname.split('/')[1]
    const lang = this.props.language
    return (
      <section
        id={translate({ idDE: 'kompetenzen', idEN: 'competences' }, 'id', lang)}
        className="section skills"
      >
        <div className="section-content skills-content">
          <h3 className="section-content-title skills-content-title">
            {translate(
              { titleDE: 'Kernkompetenzen', titleEN: 'Core competences' },
              'title',
              lang
            )}
          </h3>
          {SKILLS.map((entry, i) => {
            return (
              <div key={i} className="skills-content-entry">
                <svg className="skills-content-entry-rect" />
                <div className="skills-content-entry-text">
                  {translate(entry, 'name', lang)}
                </div>
              </div>
            )
          })}
        </div>
      </section>
    )
  }
}
