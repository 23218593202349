import React, { Component } from 'react'

import { translate } from '../helper'

const LANDING = {
  textDE: 'Clemens Wilding ist ein Softwareentwickler und Berater in Berlin.',
  textEN: 'Clemens Wilding is a software engineer and consultant in Berlin.'
}

export default class Landing extends Component {
  render() {
    // const lang = this.props.location.pathname.split('/')[1]
    const lang = this.props.language
    return (
      <section
        id={translate({ idDE: 'start', idEN: 'home' }, 'id', lang)}
        className="section landing"
      >
        <div className="section-content landing-content">
          {translate(LANDING, 'text', lang)}
        </div>
      </section>
    )
  }
}
